
import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import RegionClaimAPI from '@/API/claimDelay/regionClaim';
import fixTable from '@/hooks/checking/useFixTable';
import useDelay from '@/hooks/claimDelay/useDelay';
import downloadFile from '@/utils/claim/downloadFile';
import {
    SelectFormat,
    StatusListMap2,
    RequestTypeListEnum,
    ProgramType,
} from '@/views/Delay/type';
import { getOfferType } from '@/API/claimDelay/common';

export default defineComponent({
    setup() {
        const router = useRouter();
        const {
            overviewValue,
            spaceSize,
            requestCode,
            requestType,
            requestDate,
            status,
            programCode,
            programName,
            programType,
            dealerCode,
            dealerName,
            pagination,
            state,
            delayRouteJump,
            downLoad,
        } = useDelay();

        const requestTypeList = ref<SelectFormat[]>([
            { value: '1', title: 'Delay' },
            { value: '2', title: 'Callback' },
        ]);
        const statusList = ref<SelectFormat[]>([
            { value: '1', title: 'Submitted' },
            { value: '2', title: 'Region Approved' },
            { value: '3', title: 'Region Reject' },
            { value: '4', title: 'SIPO Approved' },
            { value: '5', title: 'SIPO Reject' },
        ]);
        const programTypeList = ref<ProgramType[]>([]);
        const genProgramTypeList = () => {
            getOfferType().then((res: any) => {
                const offerTypeList = res.incentiveCategory.reduce(
                    (totalList: any, arr: any) => {
                        return [...totalList, ...arr.offerType];
                    },
                    []
                );
                programTypeList.value = offerTypeList;
            });
        };
        genProgramTypeList();

        const tableData = ref([
            // {
            //     requestCode: 'D100001',
            //     requestType: '1',
            //     requestDate: '6/1/2021',
            //     dealerCode: '193140133',
            //     dealerName: 'Jinan Star Automobile Service Co.,Ltd.',
            //     status: '3',
            //     programCode: '2021Q1V123_001',
            //     programName: '2021Q1 V123 Customer Others',
            //     programType: '1',
            //     programPeriod: '2021.4.1~2021.4.30',
            //     vehicleTotal: 50,
            // },
            // {
            //     requestCode: 'D100001',
            //     requestType: '1',
            //     requestDate: '6/1/2021',
            //     dealerCode: '193140133',
            //     dealerName: 'Jinan Star Automobile Service Co.,Ltd.',
            //     status: '4',
            //     programCode: '2021Q1V123_001',
            //     programName: '2021Q1 V123 Customer Others',
            //     programType: '1',
            //     programPeriod: '2021.4.1~2021.4.30',
            //     vehicleTotal: 50,
            // },
        ]);
        const columns = [
            {
                title: 'Request Code',
                width: 150,

                dataIndex: 'requestCode',
            },
            {
                title: 'Request Type',
                width: 120,
                dataIndex: 'requestType',
                slots: { customRender: 'requestType' },
            },
            {
                title: 'Dealer Code',
                width: 120,
                dataIndex: 'dealerCode',
            },
            {
                title: 'Dealer Name',
                width: 200,
                dataIndex: 'dealerName',
            },
            {
                title: 'Program Code',
                width: 150,
                dataIndex: 'programCode',
            },
            {
                title: 'Program Name',
                width: 200,
                dataIndex: 'programName',
            },
            {
                title: 'Program Type',
                width: 150,
                dataIndex: 'programType',
                slots: { customRender: 'programType' },
            },
            {
                title: 'Program Period',
                width: 200,
                dataIndex: 'eligiblePeriod',
            },
            {
                title: 'Request Date',
                width: 120,
                dataIndex: 'requestDate',
            },
            {
                title: 'VIN Count',
                width: 100,
                dataIndex: 'vinCount',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                width: 150,
                slots: { customRender: 'status' },
            },
            {
                title: 'Document',
                width: 200,
                slots: { customRender: 'document' },
            },
            {
                title: 'Operation',
                width: 100,
                slots: { customRender: 'failReason' },
            },
        ];

        const mapRequestType = (text: string): string => {
            return RequestTypeListEnum[text];
        };
        const mapStatus = (text: string): string => {
            return StatusListMap2[text];
        };

        const rejectModalVisible = ref<boolean>(false);

        //* 限定表格
        const height: number =
            50 + 8 + 80 + 8 + 133 + 10 + 8 + 10 + 32 + 10 + 5 + 48 + 135 + 50;
        const tableHeight = fixTable(height);

        const getOverviewValue = () => {
            const params = {
                dealerCode: '',
                regionalManagerCode: '',
            };
            RegionClaimAPI.getOverviewValue(params).then((res) => {
                overviewValue.delayRequset = res.delayNum;
                overviewValue.delayRequsetApproved = res.delayPendingNum;
                overviewValue.callBackRequset = res.callbackNum;
                overviewValue.callBackRequsetApproved = res.callbackPendingNum;
            });
        };

        const reset = () => {
            state.selectedRows = [];
            state.selectedRowKeys = [];
            requestCode.value = '';
            requestType.value = null;
            requestDate.value = undefined;
            status.value = null;
            programCode.value = '';
            programName.value = '';
            programType.value = null;
            dealerCode.value = '';
            dealerName.value = '';
        };

        const getTableData = () => {
            state.selectedRows = [];
            state.selectedRowKeys = [];
            const params = {
                requestCode: requestCode.value,
                requestType: requestType.value || '',
                requestDate:
                    (requestDate.value?.format('YYYY/MM/DD') as string) || '',
                status: status.value || '',
                programCode: programCode.value,
                programName: programName.value,
                programType: programType.value || '',
                dealerCode: dealerCode.value,
                dealerName: dealerName.value,
                regionalManagerCode: '', //TODO regional code 等待前面流程确认 具体内容
                current: pagination.currentPage - 1,
                size: pagination.pageSize,
            };
            RegionClaimAPI.searchData(params).then((res) => {
                tableData.value = res.data;
                pagination.total = parseInt(res.totalNum);
            });
        };

        const search = () => {
            pagination.currentPage = 1;
            getTableData();
        };

        const quickSearch = (requestTypeCondition: string, statusCondition: null | string) => {
          reset();
          requestType.value = requestTypeCondition;
          status.value = statusCondition;
          search();
        }

        const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        const exportExcel = () => {
            const params = {
                url: '/claimapi/delay/region/export',
                method: 'post',
                params: {
                    requestCode: requestCode.value,
                    requestType: requestType.value || '',
                    requestDate:
                        (requestDate.value?.format('YYYY/MM/DD') as string) ||
                        '',
                    status: status.value || '',
                    programCode: programCode.value,
                    programName: programName.value,
                    programType: programType.value || '',
                    dealerCode: dealerCode.value,
                    dealerName: dealerName.value,
                    regionalManagerCode: '', //TODO regional code
                },
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };

        const judgeSelectedRowsLength = () => {
            const length = state.selectedRowKeys.length;
            if (!length) {
                message.error('Please select at least one application.');
                return true;
            }
            return false;
        };

        //* Reject Approve 按钮点击
        const selectedRowsGlobal = ref<string[]>([]);
        const approveModalVisible = ref<boolean>(false);
        const approveConfirm = () => {
            approveModalVisible.value = false;
            const params = {
                confirm: true,
                idList: selectedRowsGlobal.value,
            };
            RegionClaimAPI.approve(params).then((res) => {
                search();
                getOverviewValue();
            });
        };
        const approveCancel = () => {
            approveModalVisible.value = false;
        };
        const approve = () => {
            const rows = state.selectedRows;
            const selectedRows: any = [];
            let flag = false;
            if (judgeSelectedRowsLength()) return;
            rows.forEach((row: any) => {
                if (row.status !== '1') {
                    // Region Approved
                    flag = true;
                }
                if (row.status === '1') {
                    // Submitte
                    selectedRows.push(row.id);
                }
            });
            if (flag) {
                message.error(
                    'The application in this status can’t be operated.'
                );
            } else {
                const params = {
                    confirm: true,
                    idList: selectedRows,
                };
                RegionClaimAPI.approve(params).then((res) => {
                    if (res && res.code && res.code === '50235') {
                        approveModalVisible.value = true;
                        selectedRowsGlobal.value = selectedRows;
                    } else {
                        search();
                        getOverviewValue();
                    }
                });
            }
        };

        const reject = () => {
            const rows = state.selectedRows;
            const selectedRows: any = [];
            let flag = false;
            if (judgeSelectedRowsLength()) return;
            rows.forEach((row: any) => {
                if (row.status !== '1') {
                    // Region Approved
                    flag = true;
                }
                if (row.status === '1') {
                    // Submitte
                    selectedRows.push(row.id);
                }
            });
            if (flag) {
                message.error(
                    'The application in this status can’t be operated.'
                );
            } else {
                rejectModalVisible.value = true;
                selectedRowsGlobal.value = selectedRows;
            }
        };

        const rejectReason = ref<string>('');
        const send = () => {
            if (!rejectReason.value) {
                message.error('Please input the rejection reason.');
                return;
            }
            const params = {
                refuseReason: rejectReason.value,
                idList: selectedRowsGlobal.value,
            };
            RegionClaimAPI.reject(params).then((res) => {
                search();
                getOverviewValue();
                rejectModalVisible.value = false;
                rejectReason.value = '';
            });
        };

        const pageChange = (page: number) => {
            pagination.currentPage = page;
            getTableData();
        };
        const sizeChange = (page: number, pageSize: number) => {
            pagination.pageSize = pageSize;
            pagination.currentPage = page;
            getTableData();
        };

        const getDocument = (record: any) => {
            const params = {
                applyInfoId: record.id,
            };
            RegionClaimAPI.getDocument({ params }).then((res) => {
                downLoad(res);
            });
        };

        const gotoDetail = (record: any) => {
            router.push({
                name: 'regionClaimDetail',
                params: {
                    id: record.id,
                    requestCode: record.requestCode,
                    requestType: record.requestType,
                    requestDate: record.requestDate,
                    programCode: record.programCode,
                    programName: record.programName,
                    programType: record.programType,
                    dealerCode: record.dealerCode,
                    dealerName: record.dealerName,
                    documentName: record.documentName,
                },
            });
        };

        onMounted(() => {
            status.value = statusList.value[0].value;
            getOverviewValue();
            search();
        });
        return {
            delayRouteJump,
            spaceSize,
            tableHeight,
            requestCode,
            requestType,
            requestTypeList,
            requestDate,
            status,
            statusList,
            dealerCode,
            dealerName,
            programCode,
            programName,
            programType,
            programTypeList,
            reset,
            search,
            tableData,
            columns,
            ...toRefs(state),
            ...toRefs(overviewValue),
            ...toRefs(pagination),
            rejectModalVisible,
            rejectReason,
            pageChange,
            sizeChange,
            onSelectChange,
            exportExcel,
            approve,
            reject,
            send,
            gotoDetail,
            mapRequestType,
            mapStatus,
            getDocument,
            approveModalVisible,
            approveConfirm,
            approveCancel,
            quickSearch
        };
    },
});
